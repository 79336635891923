import { default as firstrungGtxDh2aW3Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45passwordiBclziHQNRMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexHSjQRClQ0aMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginnKR5XrtkcVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teamHA7WIRhQ0uMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93zmGG9z98ElMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addb3jGwSuJBOMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_936Y3F5TJuxAMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as indexfwgjgGSvw9Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_93jvYYenxxf1Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_93WyOBzsfUw7Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexzgx1PBAUvkMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendingWLkIrZZkslMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93YzEmaJLoNVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_932JTaia7k9YMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexZpONccACCIMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addozY3LkGR4QMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_93GOnJprF0CxMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexq628fY2tPVMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93dvJcAq9Ag5Meta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as addRDHypbE6rJMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93HHWZCaHmXjMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexozZQT268qHMeta } from "/home/forge/demo.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: "firstrun",
    path: "/firstrun",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordiBclziHQNRMeta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginnKR5XrtkcVMeta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "meet-the-team",
    path: "/meet-the-team",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: "move-notifications-index-filter",
    path: "/move-notifications/:filter?",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: "movers-add",
    path: "/movers/add",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: "movers-edit-id",
    path: "/movers/edit/:id()",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "movers",
    path: "/movers",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-add-step",
    path: "/moves/add/:step?",
    meta: _91_91step_93_93jvYYenxxf1Meta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-edit-id-step",
    path: "/moves/edit/:id()/:step?",
    meta: _91_91step_93_93WyOBzsfUw7Meta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves",
    path: "/moves",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: "moves-pending",
    path: "/moves/pending",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: "moves-view-id",
    path: "/moves/view/:id()",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "moves-void-id-step",
    path: "/moves/void/:id()/:step?",
    meta: _91_91step_93_932JTaia7k9YMeta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: "moves-void",
    path: "/moves/void",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-add",
    path: "/partner-users/add",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: "partner-users-edit-id",
    path: "/partner-users/edit/:id()",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "partner-users",
    path: "/partner-users",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93dvJcAq9Ag5Meta || {},
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "properties-add",
    path: "/properties/add",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: "properties-edit-id",
    path: "/properties/edit/:id()",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: "properties",
    path: "/properties",
    component: () => import("/home/forge/demo.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]